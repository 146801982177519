export const FETCH_HOME_PAGE_BANNER = "FETCH_HOME_PAGE_BANNER";
export const FETCH_HOME_PAGE_BANNER_FAILURE = "FETCH_HOME_PAGE_BANNER_FAILURE";
export const FETCH_HOME_PAGE_BANNER_SUCCESS = "FETCH_HOME_PAGE_BANNER_SUCCESS";

export const FETCH_HOME_OFF_PEAK = "FETCH_HOME_OFF_PEAK";
export const FETCH_HOME_OFF_PEAK_FAILURE = "FETCH_HOME_OFF_PEAK_FAILURE";
export const FETCH_HOME_OFF_PEAK_SUCCESS = "FETCH_HOME_OFF_PEAK_SUCCESS";

export const FETCH_HOME_BLOGS = "FETCH_HOME_BLOGS";
export const FETCH_HOME_BLOGS_FAILURE = "FETCH_HOME_BLOGS_FAILURE";
export const FETCH_HOME_BLOGS_SUCCESS = "FETCH_HOME_BLOGS_SUCCESS";

export const FETCH_HOME_EXPERIENCE = "FETCH_HOME_EXPERIENCE";
export const FETCH_HOME_EXPERIENCE_FAILURE = "FETCH_HOME_EXPERIENCE_FAILURE";
export const FETCH_HOME_EXPERIENCE_SUCCESS = "FETCH_HOME_EXPERIENCE_SUCCESS";

export const FETCH_HOME_TESTIMONIALS = "FETCH_HOME_TESTIMONIALS";
export const FETCH_HOME_TESTIMONIALS_FAILURE = "FETCH_HOME_TESTIMONIALS_FAILURE";
export const FETCH_HOME_TESTIMONIALS_SUCCESS = "FETCH_HOME_TESTIMONIALS_SUCCESS";

export const FETCH_HOME_EVENTS = "FETCH_HOME_EVENTS";
export const FETCH_HOME_EVENTS_FAILURE = "FETCH_HOME_EVENTS_FAILURE";
export const FETCH_HOME_EVENTS_SUCCESS = "FETCH_HOME_EVENTS_SUCCESS";

export const FETCH_HOME_DESTINATIONS = "FETCH_HOME_DESTINATIONS";
export const FETCH_HOME_DESTINATIONS_FAILURE = "FETCH_HOME_DESTINATIONS_FAILURE";
export const FETCH_HOME_DESTINATIONS_SUCCESS = "FETCH_HOME_DESTINATIONS_SUCCESS";

export const FETCH_HOME_DEALS = "FETCH_HOME_DEALS";
export const FETCH_HOME_DEALS_FAILURE = "FETCH_HOME_DEALS_FAILURE";
export const FETCH_HOME_DEALS_SUCCESS = "FETCH_HOME_DEALS_SUCCESS";