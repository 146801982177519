import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Signup from "./signup";
import Login from "./login";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useNavigate, useLocation } from "react-router-dom";

const CustomButton = styled(Button)(({ theme }) => ({
  height: "50px",
  width: "100px",
  fontWeight: "700",
  fontSize: "15px",
  borderRadius: "16px",
  textTransform: "none",
  [theme.breakpoints.down("md")]: {
    height: "35px", // Height for small screens (sm)
    widht: "45px",
  },
  [theme.breakpoints.down("sm")]: {
    height: "30px", // Height for small screens (sm)
    widht: "35px",
  },
}));

function Header(props) {
  const { isLoggedIn, user } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const toggleDrawer = (event, open) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  const list = (anchor) => (
    <Box sx={{ width: 250 }} role="presentation">
      <List>
        <Box
          ml={4}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItem: "center",
            pr: 2,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/logo-offpeakbreak.png`}
            height={60}
            alt="Logo"
            onClick={() => navigate("/")}
          />
          <IconButton onClick={(e) => toggleDrawer(e, false)}>
            <CancelIcon />
          </IconButton>
        </Box>
      </List>
      <Divider />
      <List>
        <ListItem>
          <ListItemButton
            onClick={(e) => {
              scrollToPlace("experience");
              toggleDrawer(e, false);
            }}
          >
            Experiences
          </ListItemButton>{" "}
        </ListItem>{" "}
        <ListItem>
          <ListItemButton
            onClick={(e) => {
              scrollToPlace("OPBBreak");
              toggleDrawer(e, false);
            }}
          >
            OPB Breaks
          </ListItemButton>{" "}
        </ListItem>{" "}
        <ListItem>
          <ListItemButton onClick={(e) => navigate("/elite")}>
            Elite Club
          </ListItemButton>{" "}
        </ListItem>{" "}
        <ListItem>
          <ListItemButton
            onClick={(e) => {
              scrollToPlace("blog");
              toggleDrawer(e, false);
            }}
          >
            Blog
          </ListItemButton>{" "}
        </ListItem>{" "}
        <ListItem>
          <ListItemButton
            onClick={(e) => {
              navigate("/contact-us");
              toggleDrawer(e, false);
            }}
          >
            ContactUs
          </ListItemButton>
        </ListItem>
        <CustomButton
          variant="contained"
          sx={{
            background: "#000",
            ml: 4,
            color: "#fff",
            "@media (min-width: 400px)": {
              display: "none",
            },
          }}
          onClick={() => setOpenSignUp(true)}
        >
          Signup
        </CustomButton>
      </List>
    </Box>
  );

  function scrollToPlace(place) {
    if (document.querySelector(`#${place}`)) {
      document
        .querySelector(`#${place}`)
        ?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/");
      new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
        document
          .querySelector(`#${place}`)
          ?.scrollIntoView({ behavior: "smooth" })
      );
    }
  }

  return (
    <>
      <Signup
        open={openSignUp}
        onClose={() => {
          setOpenSignUp(false);
        }}
      />
      <Login
        open={openLogin}
        onClose={() => {
          setOpenLogin(false);
        }}
      />
      <Box
        display="flex"
        sx={{
          width: { lg: "85%" },
          margin: "auto",
          padding: "20px 15px",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button onClick={(e) => toggleDrawer(e, true)} sx={{ mr: 2 }}>
                <MenuIcon />
              </Button>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={(e) => toggleDrawer(e, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box sx={{ width: { xs: "60px", sm: "80px", md: "100px" } }}>
            <img
              src={"https://dexc5w8fyuii9.cloudfront.net/demo/logo.png"}
              alt="Logo"
              style={{ maxHeight: "60px", maxWidth: "100%", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              justifyContent: "center",
              width: "60%",
            }}
          >
            <ul
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                listStyle: "none",
                justifyContent: "space-evenly",
              }}
            >
              <li
                style={{ cursor: "pointer", fontWeight: "500" }}
                onClick={() => scrollToPlace("experience")}
              >
                Experiences
              </li>
              <li
                style={{ cursor: "pointer", fontWeight: "500" }}
                onClick={() => scrollToPlace("OPBBreak")}
              >
                OPB Breaks
              </li>
              <li
                style={{ cursor: "pointer", fontWeight: "500" }}
                onClick={() => navigate("/elite")}
              >
                Elite Club
              </li>
              <li
                style={{ cursor: "pointer", fontWeight: "500" }}
                onClick={() => scrollToPlace("blog")}
              >
                Blog
              </li>
              <li
                onClick={() => navigate("/contact-us")}
                style={{ cursor: "pointer", fontWeight: "500" }}
              >
                Contact Us
              </li>
            </ul>
          </Box>
          {!isLoggedIn ? (
            <Box>
              <CustomButton
                variant="contained"
                sx={{
                  background: "#fff",
                  color: "#0984e3",
                  marginRight: 1,
                  width: "80px",
                }}
                onClick={() => navigate(`/login?location=${location.pathname}`)}
              >
                Login
              </CustomButton>
              <CustomButton
                variant="contained"
                sx={{
                  background: "#000",
                  color: "#fff",
                  "@media (max-width: 400px)": {
                    display: "none",
                  },
                }}
                onClick={() => navigate("/login")}
              >
                Signup
              </CustomButton>
            </Box>
          ) : (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="See menus">
                <IconButton
                  onClick={(e) => setAnchorElUser(e.currentTarget)}
                  sx={{ p: 0 }}
                  disableRipple
                >
                  <Avatar
                    src={user?.firstName}
                    alt={user?.firstName}
                    sx={{ mr: 1 }}
                  ></Avatar>
                  <Typography sx={{ mr: 1 }}>
                    {user?.firstName?.toUpperCase()}{" "}
                    {user?.lastName?.toUpperCase()}
                  </Typography>
                  <KeyboardArrowDownRoundedIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
              >
                <MenuItem onClick={() => navigate("/profile")}>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/my-booking")}>
                  <Typography textAlign="center">My booking</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    sessionStorage.removeItem("token");
                    sessionStorage.removeItem("user");
                    window.location.reload();
                  }}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Header;
