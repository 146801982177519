import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import createCustomTheme from './../theme/theme';
import store from './store';
import { Provider } from 'react-redux';
import { AppRootContainer } from './app-root/app-root.container';

function App() {
  const fetchThemeColors = () => {
    // Implement your logic to fetch theme colors from the database or external source
    return {
      primaryColor: '#4bb4c9', // Fetch primary color from the database
      secondaryColor: '#212121', // Fetch secondary color from the database
    };
  };
  
  const [themeColors, setThemeColors] = useState(null);
  
  useEffect(() => {
    const colors = fetchThemeColors();
    console.log("Colors: ", colors);
    setThemeColors(colors);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={themeColors ? createCustomTheme(themeColors.primaryColor, themeColors.secondaryColor) : createCustomTheme("#ffffff", "#000000")}>
        {themeColors && <BrowserRouter>
          <AppRootContainer />
        </BrowserRouter>
        }
      </ThemeProvider>
    </Provider>
  );
}

export default App;
