import AppRoot from './app-root.component';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    
};

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    loggedUser: state.userPage.loggedUser,
});

export const AppRootContainer = connect(mapStateToProps, mapDispatchToProps)(AppRoot);