import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { Typography } from 'antd';

export default function Login(props) {
  const { open, onClose } = props;
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  return (
    <div>
      <Dialog open={open} onClose={() => {onClose(); reset();}}>
        <DialogTitle>Login</DialogTitle>
            <form onSubmit={handleSubmit(alert)}>
        <DialogContent>
                    <TextField
                      fullWidth
                      label="Email Id"
                      name="email"
                      variant="outlined"
                      {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                      error={!!errors.email}
                      helperText={errors.email && 'Please enter a valid email'}
                    />
                
                    <TextField
                      sx={{ mt: 3 }}
                      fullWidth
                      label="Password"
                      name="password"
                      variant="outlined"
                      {...register('password', { required: true })}
                      error={!!errors.password}
                      helperText={errors.password && 'Please enter a valid password'}
                    />
            
        </DialogContent>
        <Box sx={{ pt: 0, px: 3, pb: 3, width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ cursor: "pointer" }}>FORGOT PASSWORD?</Typography>
          <Box sx={{ p: 0 }}>
            <Button
              onClick={() => {
                onClose();
                reset();
              }}>Cancel</Button>
            <Button type="submit" variant='contained'>Login</Button>
          </Box>
        </Box>
        </form>
      </Dialog>
    </div>
  );
}