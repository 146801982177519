import * as actionType from "./userActionType";

const initialState = {
  isLoading: false,
  isRegistered: false,
  isLoggedIn: sessionStorage.getItem('user') !== null ? true : false,
  loggedUser: sessionStorage.getItem('user') !== null ? JSON.parse(sessionStorage.getItem('user')) : {},
  isNumberValid: false,
  isUpdated: false,
  profile: {}
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.USER_LOGIN:
            return {
                ...state,
                isLoading: true,
                isNumberValid: false,
            };
        case actionType.USER_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isNumberValid: true,
            };
        case actionType.USER_OTP_CHECK:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.USER_OTP_CHECK_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.USER_OTP_CHECK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.USER_REGISTER:
            return {
                ...state,
                isLoading: true,
                isRegistered: false
            };
        case actionType.USER_REGISTER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.USER_REGISTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isRegistered: true
            };
        case actionType.FORGOT_PASSWORD:
            return {
                ...state,
                isLoading: true,
                isSent: false
            };
        case actionType.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSent: false
            };
        case actionType.RESET_PASSWORD:
            return {
                ...state,
                isLoading: true,
                isReset: false
            };
        case actionType.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isReset: true
            };
        case actionType.GET_PROFILE:
            return {
                ...state,
                isLoading: true
            };
        case actionType.GET_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                profile: action.payload ? action.payload.data : {},
            };
        case actionType.UPDATE_PROFILE:
            return {
                ...state,
                isLoading: true,
                isUpdated: false
            };
        case actionType.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isUpdated: true,
            };
        default:
            return state;
    }
}