import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './App/protected.route';
const HomeContainer = lazy(() => import('./views/home/home.container'));
const EliteContainer = lazy(() => import('./views/elite/elite.container'));
const ContactUsContainer = lazy(() => import('./views/contact-us/contact-us.container'));
const EventDetailContainer = lazy(() => import('./views/event-detail/event-detail.container'));
const TermsContainer = lazy(() => import('./views/terms/terms.container'));
const FaqsContainer = lazy(() => import('./views/faqs/faqs.container'));
const EventsContainer = lazy(() => import('./views/events/events.container'));
const LoginContainer = lazy(() => import('./views/login/login.container'));
const ProfileContainer = lazy(() => import('./views/profile/profile.container'));
const ThanksContainer = lazy(() => import('./views/thanks/thanks.container'));
const BookingContainer = lazy(() => import('./views/booking/booking.container'));

export const RoutePath = () => (
    <Routes scrollToTop>
      <Route path='/' element={<Suspense fallback={<>...</>}><HomeContainer /></Suspense>} />
      <Route path='/elite' element={<Suspense fallback={<>...</>}><EliteContainer /></Suspense>} />
      <Route path='/contact-us' element={<Suspense fallback={<>...</>}><ContactUsContainer /></Suspense>} />
      <Route path='/event-detail/:id' element={<Suspense fallback={<>...</>}><EventDetailContainer /></Suspense>} />
      <Route path='/terms-condition' element={<Suspense fallback={<>...</>}><TermsContainer /></Suspense>} />
      <Route path='/faqs' element={<Suspense fallback={<>...</>}><FaqsContainer /></Suspense>} />
      <Route path='/events' element={<Suspense fallback={<>...</>}><EventsContainer /></Suspense>} />
      <Route path='/login' element={<Suspense fallback={<>...</>}><LoginContainer /></Suspense>} />
      <Route path='/profile' element={<Suspense fallback={<>...</>}><ProfileContainer /></Suspense>} />
      <Route path='/thanks' element={<Suspense fallback={<>...</>}><ThanksContainer /></Suspense>} />
      <Route path='/my-booking' element={<Suspense fallback={<>...</>}><BookingContainer /></Suspense>} />
    </Routes>
);
