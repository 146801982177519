export const FETCH_BASE_ENQUIRY = "FETCH_BASE_ENQUIRY";
export const FETCH_BASE_ENQUIRY_FAILURE = "FETCH_BASE_ENQUIRY_FAILURE";
export const FETCH_BASE_ENQUIRY_SUCCESS = "FETCH_BASE_ENQUIRY_SUCCESS";

export const FETCH_MANAGEMENT_GRADE = "FETCH_MANAGEMENT_GRADE";
export const FETCH_MANAGEMENT_GRADE_FAILURE = "FETCH_MANAGEMENT_GRADE_FAILURE";
export const FETCH_MANAGEMENT_GRADE_SUCCESS = "FETCH_MANAGEMENT_GRADE_SUCCESS";

export const FETCH_USE_CASE = "FETCH_USE_CASE";
export const FETCH_USE_CASE_FAILURE = "FETCH_USE_CASE_FAILURE";
export const FETCH_USE_CASE_SUCCESS = "FETCH_USE_CASE_SUCCESS";

export const FETCH_STAY_OPTIONS = "FETCH_STAY_OPTIONS";
export const FETCH_STAY_OPTIONS_FAILURE = "FETCH_STAY_OPTIONS_FAILURE";
export const FETCH_STAY_OPTIONS_SUCCESS = "FETCH_STAY_OPTIONS_SUCCESS";

export const FETCH_EXPERIENCE_OPTIONS = "FETCH_EXPERIENCE_OPTIONS";
export const FETCH_EXPERIENCE_OPTIONS_FAILURE = "FETCH_EXPERIENCE_OPTIONS_FAILURE";
export const FETCH_EXPERIENCE_OPTIONS_SUCCESS = "FETCH_EXPERIENCE_OPTIONS_SUCCESS";

export const FETCH_SERVICE_PROVIDER = "FETCH_SERVICE_PROVIDER";
export const FETCH_SERVICE_PROVIDER_FAILURE = "FETCH_SERVICE_PROVIDER_FAILURE";
export const FETCH_SERVICE_PROVIDER_SUCCESS = "FETCH_SERVICE_PROVIDER_SUCCESS";

export const FETCH_FIXED = "FETCH_FIXED";
export const FETCH_FIXED_FAILURE = "FETCH_FIXED_FAILURE";
export const FETCH_FIXED_SUCCESS = "FETCH_FIXED_SUCCESS";

export const CREATE_ENQUIRY = "CREATE_ENQUIRY";
export const CREATE_ENQUIRY_FAILURE = "CREATE_ENQUIRY_FAILURE";
export const CREATE_ENQUIRY_SUCCESS = "CREATE_ENQUIRY_SUCCESS";

export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";

export const FETCH_EVENT_DETAILS = "FETCH_EVENT_DETAILS";
export const FETCH_EVENT_DETAILS_FAILURE = "FETCH_EVENT_DETAILS_FAILURE";
export const FETCH_EVENT_DETAILS_SUCCESS = "FETCH_EVENT_DETAILS_SUCCESS";

export const FETCH_EVENT_GALLERY = "FETCH_EVENT_GALLERY";
export const FETCH_EVENT_GALLERY_FAILURE = "FETCH_EVENT_GALLERY_FAILURE";
export const FETCH_EVENT_GALLERY_SUCCESS = "FETCH_EVENT_GALLERY_SUCCESS";

export const FETCH_EVENT_DAY = "FETCH_EVENT_DAY";
export const FETCH_EVENT_DAY_FAILURE = "FETCH_EVENT_DAY_FAILURE";
export const FETCH_EVENT_DAY_SUCCESS = "FETCH_EVENT_DAY_SUCCESS";

export const FETCH_EVENT_DATES_BY_ID = "FETCH_EVENT_DATES_BY_ID";
export const FETCH_EVENT_DATES_BY_ID_FAILURE = "FETCH_EVENT_DATES_BY_ID_FAILURE";
export const FETCH_EVENT_DATES_BY_ID_SUCCESS = "FETCH_EVENT_DATES_BY_ID_SUCCESS";

export const FETCH_TNC_BY_ID = "FETCH_TNC_BY_ID";
export const FETCH_TNC_BY_ID_FAILURE = "FETCH_TNC_BY_ID_FAILURE";
export const FETCH_TNC_BY_ID_SUCCESS = "FETCH_TNC_BY_ID_SUCCESS";

export const CREATE_COST = "CREATE_COST";
export const CREATE_COST_FAILURE = "CREATE_COST_FAILURE";
export const CREATE_COST_SUCCESS = "CREATE_COST_SUCCESS";

export const EVENT_LIST_BY_LOCATION = "EVENT_LIST_BY_LOCATION";
export const EVENT_LIST_BY_LOCATION_FAILURE = "EVENT_LIST_BY_LOCATION_FAILURE";
export const EVENT_LIST_BY_LOCATION_SUCCESS = "EVENT_LIST_BY_LOCATION_SUCCESS";

export const CREATE_BOOKING = "CREATE_BOOKING";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";

export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONFIRM_PAYMENT_FAILURE = "CONFIRM_PAYMENT_FAILURE";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";

export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKINGS_FAILURE = "GET_BOOKINGS_FAILURE";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";

export const GET_BOOKING = "GET_BOOKING";
export const GET_BOOKING_FAILURE = "GET_BOOKING_FAILURE";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";

export const FETCH_EVENTS = "FETCH_EVENTS";
export const FETCH_EVENTS_FAILURE = "FETCH_EVENTS_FAILURE";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";

export const SET_BOOKING_INIT = "SET_BOOKING_INIT";