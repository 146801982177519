export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_OTP_CHECK = "USER_OTP_CHECK";
export const USER_OTP_CHECK_FAILURE = "USER_OTP_CHECK_FAILURE";
export const USER_OTP_CHECK_SUCCESS = "USER_OTP_CHECK_SUCCESS";

export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";