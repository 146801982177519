import * as actionType from "./enquiryActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  baseEnquiry: [],
  managementGrade: [],
  useCase: [],
  stayOptions: [],
  experienceOptions: [],
  serviceProvider: [],
  fixed: [],
  enquiryResult: [],
  eventDetails: {},
  eventGallery: [],
  eventDay: [],
  eventDates: [],
  eventTNC: {},
  enquiryCost: {},
  events: [],
  booking: null,
  paymentConfirm: false,
  bookings: [],
  bookingById: {},
  allEvents: []
};

export const enquiryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_BASE_ENQUIRY:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_BASE_ENQUIRY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_BASE_ENQUIRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                baseEnquiry: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_MANAGEMENT_GRADE:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_MANAGEMENT_GRADE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_MANAGEMENT_GRADE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                managementGrade: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_USE_CASE:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_USE_CASE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_USE_CASE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                useCase: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_STAY_OPTIONS:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_STAY_OPTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_STAY_OPTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                stayOptions: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_EXPERIENCE_OPTIONS:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_EXPERIENCE_OPTIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EXPERIENCE_OPTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                experienceOptions: action.payload ? action.payload.data : []
            };
        case actionType.FETCH_SERVICE_PROVIDER:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_SERVICE_PROVIDER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_SERVICE_PROVIDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceProvider: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_FIXED:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_FIXED_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_FIXED_SUCCESS:
            return {
                ...state,
                isLoading: false,
                fixed: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_ENQUIRY:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_ENQUIRY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CREATE_ENQUIRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true,
                enquiryResult: action.payload ? action.payload.data : [],
            };
        case actionType.CONTACT_US:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CONTACT_US_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CONTACT_US_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_EVENT_DETAILS:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.FETCH_EVENT_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EVENT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eventDetails: action.payload ? action.payload.data : {},
            };
            case actionType.FETCH_EVENT_GALLERY:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.FETCH_EVENT_GALLERY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EVENT_GALLERY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eventGallery: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_EVENT_DAY:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.FETCH_EVENT_DAY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EVENT_DAY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eventDay: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_EVENT_DATES_BY_ID:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.FETCH_EVENT_DATES_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EVENT_DATES_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eventDates: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_TNC_BY_ID:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.FETCH_TNC_BY_ID_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_TNC_BY_ID_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eventTNC: action.payload ? action.payload.data : {},
            };
        case actionType.CREATE_COST:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.CREATE_COST_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CREATE_COST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                enquiryCost: action.payload ? action.payload.data : {},
            };
        case actionType.EVENT_LIST_BY_LOCATION:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.EVENT_LIST_BY_LOCATION_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.EVENT_LIST_BY_LOCATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                events: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_BOOKING:
            return {
                ...state,
                isLoading: true,
                booking: null
            };
        case actionType.CREATE_BOOKING_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CREATE_BOOKING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                booking: action.payload ? action.payload.data : null,
            };
        case actionType.CONFIRM_PAYMENT:
            return {
                ...state,
                isLoading: true,
                paymentConfirm: false
            };
        case actionType.CONFIRM_PAYMENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CONFIRM_PAYMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paymentConfirm: true,
            };
        case actionType.GET_BOOKINGS:
            return {
                ...state,
                isLoading: true
            };
        case actionType.GET_BOOKINGS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.GET_BOOKINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                bookings: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_EVENTS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                allEvents: action.payload ? action.payload.data : [],
            };
        case actionType.SET_BOOKING_INIT:
            return {
                ...state,
                isLoading: true,
                paymentConfirm: false,
                booking: null
            };
        default:
            return state;
    }
}