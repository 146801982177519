import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from './../../routes';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-18-image-lightbox/style.css';


export default function AppRoot(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, loggedUser } = props;
  // const theme = useTheme();

  return (
    <Box>
      <CssBaseline />
      <ToastContainer />
      <Header isLoggedIn={isLoggedIn} user={loggedUser}/>
      <RoutePath />
      <Footer isLoggedIn={isLoggedIn} user={loggedUser}/>
    </Box>
  );
}