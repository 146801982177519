import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

function Footer(props) {
  const { isLoggedIn, user } = props;
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box sx={{ background: "#fafafa", py: 5 }}>
        <Header isLoggedIn={isLoggedIn} user={user} />
      </Box>
      <Box sx={{ background: "#F5F5F5" }}>
        <Box
          sx={{
            width: { xs: "97%", md: "80%" },
            margin: "auto",
            padding: "20px 0px",
          }}
        >
          <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
            <Typography
              variant="p"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/terms-condition")}
            >
              Terms & Conditions of use and Privacy Policy{" "}
            </Typography>{" "}
            |
            <Typography
              variant="span"
              sx={{
                color: (theme) => theme.palette.primary.main,
                cursor: "pointer",
              }}
              onClick={() => navigate("/faqs")}
            >
              {" "}
              FAQs
            </Typography>
          </Box>
          <Grid
            container
            mt={2}
            sx={{ flexDirection: { xs: "column-reverse", md: "row" } }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",

                    md: "left",
                  },
                  color: "gray",
                  marginTop: { xs: "15px", md: "0px" },
                }}
              >
                Copyright © 2023 All rights reserved
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
              }}
            >
              <Box sx={{ width: { xs: "60%", sm: "40%" } }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <a
                    href="https://www.facebook.com/OffPeakBreak"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookOutlinedIcon
                      sx={{
                        color: "black",
                        "&:hover": {
                          color: "darkgray",
                          cursor: "pointer",
                        },
                      }}
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/offpeakbreak?igsh=YjV5MG85NWt6a3Jp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon
                      sx={{
                        color: "black",
                        "&:hover": {
                          color: "darkgray",
                          cursor: "pointer",
                        },
                      }}
                    />
                  </a>

                  <a
                    href="https://www.linkedin.com/company/offpeakbreak/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon
                      sx={{
                        color: "black",
                        "&:hover": {
                          color: "darkgray",
                          cursor: "pointer",
                        },
                      }}
                    />
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Footer;
