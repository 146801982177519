export const FETCH_ELITE_PAGE_BANNER = "FETCH_ELITE_PAGE_BANNER";
export const FETCH_ELITE_PAGE_BANNER_FAILURE = "FETCH_ELITE_PAGE_BANNER_FAILURE";
export const FETCH_ELITE_PAGE_BANNER_SUCCESS = "FETCH_ELITE_PAGE_BANNER_SUCCESS";

export const FETCH_ELITE_OFF_PEAK = "FETCH_ELITE_OFF_PEAK";
export const FETCH_ELITE_OFF_PEAK_FAILURE = "FETCH_ELITE_OFF_PEAK_FAILURE";
export const FETCH_ELITE_OFF_PEAK_SUCCESS = "FETCH_ELITE_OFF_PEAK_SUCCESS";

export const FETCH_ELITE_OUTCOME = "FETCH_ELITE_OUTCOME";
export const FETCH_ELITE_OUTCOME_FAILURE = "FETCH_ELITE_OUTCOME_FAILURE";
export const FETCH_ELITE_OUTCOME_SUCCESS = "FETCH_ELITE_OUTCOME_SUCCESS";

export const FETCH_ELITE_MEMORIES = "FETCH_ELITE_MEMORIES";
export const FETCH_ELITE_MEMORIES_FAILURE = "FETCH_ELITE_MEMORIES_FAILURE";
export const FETCH_ELITE_MEMORIES_SUCCESS = "FETCH_ELITE_MEMORIES_SUCCESS";

export const FETCH_ELITE_THEMES = "FETCH_ELITE_THEMES";
export const FETCH_ELITE_THEMES_FAILURE = "FETCH_ELITE_THEMES_FAILURE";
export const FETCH_ELITE_THEMES_SUCCESS = "FETCH_ELITE_THEMES_SUCCESS";

export const SEND_ELITE_ENQUIRY = "SEND_ELITE_ENQUIRY";
export const SEND_ELITE_ENQUIRY_FAILURE = "SEND_ELITE_ENQUIRY_FAILURE";
export const SEND_ELITE_ENQUIRY_SUCCESS = "SEND_ELITE_ENQUIRY_SUCCESS";
