import * as actionType from "./eliteActionType";

const initialState = {
  isLoading: false,
  elitePageBanners: [],
  eliteOffPeak: [],
  eliteOutcomes: [],
  eliteMemories: [],
  eliteThemes: [],
  isSaved: false
};

export const eliteReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_ELITE_PAGE_BANNER:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_ELITE_PAGE_BANNER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_ELITE_PAGE_BANNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                elitePageBanners: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_ELITE_OFF_PEAK:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_ELITE_OFF_PEAK_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_ELITE_OFF_PEAK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eliteOffPeak: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_ELITE_OUTCOME:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_ELITE_OUTCOME_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_ELITE_OUTCOME_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eliteOutcomes: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_ELITE_MEMORIES:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_ELITE_MEMORIES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_ELITE_MEMORIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eliteMemories: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_ELITE_THEMES:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_ELITE_THEMES_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_ELITE_THEMES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eliteThemes: action.payload ? action.payload.data : [],
            };
        case actionType.SEND_ELITE_ENQUIRY:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.SEND_ELITE_ENQUIRY_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.SEND_ELITE_ENQUIRY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        default:
            return state;
    }
}