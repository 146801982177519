import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';

export default function Signup(props) {
  const { open, onClose } = props;
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();

  return (
    <div>
      <Dialog open={open} onClose={() => {onClose(); reset();}}>
        <DialogTitle>Signup</DialogTitle>
            <form onSubmit={handleSubmit(alert)}>
        <DialogContent>
              <Grid container spacing={3}>
              <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="userName"
                      variant="outlined"
                      {...register('userName', { required: true })}
                      error={!!errors.userName}
                      helperText={errors.userName && 'Please enter a valid first name'}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="lastName"
                      variant="outlined"
                      {...register('lastName', { required: true })}
                      error={!!errors.lastName}
                      helperText={errors.lastName && 'Please enter a valid last name'}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                      error={!!errors.email}
                      helperText={errors.email && 'Please enter a valid email address'}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Mobile Number"
                      name="mobileNumber"
                      variant="outlined"
                      {...register('mobileNumber', { required: true })}
                      error={!!errors.mobileNumber}
                      helperText={errors.mobileNumber && 'Please enter a valid mobile number'}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      variant="outlined"
                      {...register('password', { required: true })}
                      error={!!errors.password}
                      helperText={errors.password && 'Please enter a valid password'}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      name="confpassword"
                      variant="outlined"
                      {...register('confpassword',
                        { required: true, validate: (val) => {
                          if (watch('password') != val) {
                            return "Your passwords do no match";
                          }
                      } })}
                      error={!!errors.confpassword}
                      helperText={errors.confpassword && 'Password did not match'}
                    />
                </Grid>
              </Grid>   
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose();
              reset();
            }}>Cancel</Button>
          <Button type="submit" variant='contained'>Signup</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}