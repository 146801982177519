import { combineReducers } from 'redux';
import { homeReducer as homePage } from './../api/home/homeReducer';
import { enquiryReducer as enquiryPage } from './../api/enquiry/enquiryReducer';
import { userReducer as userPage } from './../api/user/userReducer';
import { eliteReducer as elitePage } from './../api/elite/eliteReducer';

const appReducer = combineReducers({
    homePage,
    enquiryPage,
    userPage,
    elitePage
});

export default appReducer;