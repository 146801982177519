import * as actionType from "./homeActionType";

const initialState = {
  isLoading: false,
  homePageBanners: [],
  homeOffPeak: [],
  homeBlogs: [],
  homeExperiences: [],
  homeTestimonials: [],
  homeEvents: [],
  homeDestinations: [],
  homeDeals: []
};

export const homeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.FETCH_HOME_PAGE_BANNER:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_PAGE_BANNER_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_PAGE_BANNER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homePageBanners: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_OFF_PEAK:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_OFF_PEAK_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_OFF_PEAK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeOffPeak: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_BLOGS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_BLOGS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_BLOGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeBlogs: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_EXPERIENCE:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_EXPERIENCE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_EXPERIENCE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeExperiences: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_TESTIMONIALS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_TESTIMONIALS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_TESTIMONIALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeTestimonials: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_EVENTS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_EVENTS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeEvents: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_DESTINATIONS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_DESTINATIONS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_DESTINATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeDestinations: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_HOME_DEALS:
            return {
                ...state,
                isLoading: true,
            };
        case actionType.FETCH_HOME_DEALS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_HOME_DEALS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                homeDeals: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}